import CheckIcon from '@mui/icons-material/Check';
import {
  alpha,
  CircularProgress,
  keyframes,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { translations } from '../../../en';
import { Field, getErrorMessage } from '../../../features/form';
import { slugify } from '../helpers/slugify';

const APPENDIX_MAX_WIDTH = 120;
const SAFE_SPACE = 5;

const AnimationWrapper = styled(Stack)({
  opacity: 1,
  animation: `0.5s ${keyframes({
    from: { opacity: 1 },
    to: { opacity: 0 },
  })} 1s linear forwards`,
});

const IndicatorWrapper = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  left: `calc(100% + ${theme.spacing(1)})`,
}));

const DomainAppendix = styled('div')<{ coverIsVisible: boolean }>(
  ({ theme, coverIsVisible }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    maxWidth: APPENDIX_MAX_WIDTH,
    top: 20,
    right: theme.spacing(2),
    mariginLeft: theme.spacing(1),
    overflow: 'hidden',
    transform: 'translateY(-50%)',
    zIndex: 1,
    transition: 'all 0.5s',
    '&:hover': {
      maxWidth: '100%',
      backgroundColor: theme.palette.background.default,
      ':before': {
        opacity: 0,
      },
    },
    ':before': {
      content: '""',
      background: `linear-gradient(90deg, ${alpha(
        theme.palette.background.default,
        1,
      )} 0%, rgba(255,255,255,0) 100%)`,
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: 20,
      opacity: coverIsVisible ? 1 : 0,
      transition: 'all 0.5s',
    },
  }),
);

type DomainInputProps = {
  field: Field<string>;
  checkDomain: (domain: string) => void;
  isValid: boolean;
  isLoading: boolean;
  disabled: boolean;
  error: string | null;
};

export const DomainInput = ({
  field,
  checkDomain,
  isLoading,
  isValid,
  disabled,
  error,
}: DomainInputProps) => {
  const appendixRef = useRef<HTMLDivElement>(null);
  const appendix = useMemo(
    () => `.${new URL(location.origin).host}`,
    [location],
  );

  useEffect(() => {
    // Solve a race condition when response arrives after you change the value
    if (!field.inputProps.hasError) {
      const mappedError =
        error === 'Domain contains forbidden keyword.'
          ? 'Try your business domain. This one is forbidden or reserved.'
          : error;

      field.handlers.setApiError(mappedError || '');
    }
  }, [error]);

  useEffect(() => {
    if (field.inputProps.value) {
      checkDomain(field.inputProps.value);
    }
  }, []);

  return (
    <Stack sx={{ position: 'relative' }}>
      <DomainAppendix
        coverIsVisible={
          !!appendixRef.current &&
          appendixRef.current.offsetWidth > APPENDIX_MAX_WIDTH
        }
      >
        <Typography color="text.secondary" fontSize={12} ref={appendixRef}>
          {appendix}
        </Typography>
      </DomainAppendix>
      <IndicatorWrapper alignItems="center">
        {isLoading && field.inputProps.value && <CircularProgress size={24} />}
        {isValid && !field.inputProps.hasError && !isLoading && (
          <AnimationWrapper>
            <CheckIcon />
          </AnimationWrapper>
        )}
      </IndicatorWrapper>

      <TextField
        error={field.inputProps.hasError || undefined}
        helperText={field.inputProps.error}
        inputProps={{
          style: { paddingRight: APPENDIX_MAX_WIDTH + SAFE_SPACE },
        }}
        size="small"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          // Reset API error when starting to type.
          field.handlers.setApiError('');
          // Set touched to start validating from backend.
          field.handlers.setTouched(true);
          field.inputProps.onChange(event);
          checkDomain(slugify(event.target.value));
        }}
        value={field.inputProps.value}
        onBlur={(event) => {
          const slugifiedValue = slugify(event.target.value);
          field.handlers.setError(
            getErrorMessage(slugifiedValue, field.validations),
          );
          field.handlers.setValue(slugifiedValue);
          field.handlers.setTouched(true);
        }}
        disabled={disabled}
        data-testid="domain"
        placeholder={translations.registration.form.fields.domain}
        label={translations.registration.form.fields.domain}
      />
    </Stack>
  );
};

export default DomainInput;
