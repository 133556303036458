/* tslint:disable */

export default () => {
  (window as any).smartlook ||
    (function (d) {
      const o: any = ((window as any).smartlook = function () {
          o.api.push(arguments);
        }),
        h = d.getElementsByTagName('head')[0];
      const c = d.createElement('script');
      o.api = [];
      c.async = true;
      c.type = 'text/javascript';
      c.charset = 'utf-8';
      c.src = 'https://rec.smartlook.com/recorder.js';
      h.appendChild(c);
    })(document);
  (window as any).smartlook('init', '384fb9515111a78e7a749414d1199b707722f0fb');
};
