import { styled } from '@mui/material';
import React from 'react';
import { validateFields } from './helpers/validateFields';

interface ChildrenRenderProps<FieldsT> {
  fields: FieldsT;
}

interface Props<FieldsT> {
  fields: FieldsT;
  onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
  children: (props: ChildrenRenderProps<FieldsT>) => JSX.Element;
}

const StyledForm = styled('form')({
  width: '100%',
});

type FormT = <FieldsT>(props: Props<FieldsT>) => JSX.Element;

const Form: FormT = ({ children, fields, onSubmit }) => (
  <StyledForm
    onSubmit={(e) => {
      e.preventDefault();
      validateFields(fields, onSubmit);
    }}
  >
    {children({ fields })}
  </StyledForm>
);

export default Form;
