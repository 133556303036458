import { FocusEventHandler, useEffect, useState } from 'react';
import {
  InputChangeHandler,
  SetStateAction,
  ValidationFunction,
} from '../../../types';
import { FormField, InputValue, trackError } from '../../../utils/tracking';
import { getErrorMessage } from '../helpers/getErrorMessage';

export type Field<T> = {
  handlers: {
    setApiError: SetStateAction<string>;
    setError: SetStateAction<string>;
    setValue: SetStateAction<T>;
    setTouched: SetStateAction<boolean>;
  };
  inputProps: {
    value: T;
    error: string;
    hasError: boolean;
    touched: boolean;
    onChange: InputChangeHandler;
    onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  };
  validations: Array<ValidationFunction<T>>;
};

export function useCreateField<T extends InputValue>(
  { defaultValue, fieldName }: { defaultValue: T; fieldName: FormField },
  ...validations: Array<ValidationFunction<T>>
): Field<T> {
  const [value, setValue] = useState(defaultValue);
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState('');
  const [touched, setTouched] = useState(false);

  const onChange: InputChangeHandler = (e) => {
    const targetValue = (e.target.type === 'checkbox'
      ? e.target.checked
      : e.target.value) as unknown as T;

    if (touched) {
      setError(getErrorMessage<T>(targetValue, validations));
    }

    setValue(targetValue);
  };

  const onBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = () => {
    if (!touched) {
      setTouched(true);
    }

    const errorMessage = getErrorMessage<T>(value, validations);

    setError(errorMessage);
  };

  const resolvedError = apiError || error;

  useEffect(() => {
    if (resolvedError) {
      trackError({
        errorMessage: resolvedError,
        field: fieldName,
        value,
      });
    }
  }, [resolvedError]);

  return {
    handlers: { setError, setValue, setTouched, setApiError },
    inputProps: {
      error: resolvedError,
      hasError: !!resolvedError,
      onBlur,
      touched,
      onChange,
      value,
    },
    validations,
  };
}
