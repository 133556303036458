import { validate as validateEmail } from 'email-validator';
import { VALIDATIONS } from '../types';

export const required = (key?: VALIDATIONS) => (value?: string | boolean) => {
  const errorMessage = key ? key : VALIDATIONS.REQUIRED;
  return !value ? errorMessage : VALIDATIONS.OK;
};

export const requiredConditions = (value?: string | boolean) =>
  !value ? VALIDATIONS.TERMS_REQUIRED : VALIDATIONS.OK;

export const minLength =
  (limit: number) =>
  (value: string | boolean = '') =>
    typeof value === 'string' && value.length < limit
      ? { key: VALIDATIONS.TOO_SHORT, values: { limit } }
      : VALIDATIONS.OK;

export const minLengthDigits =
  (limit: number) =>
  (value: string | boolean = '') =>
    typeof value === 'string' && value.length > 0 && value.length < limit
      ? { key: VALIDATIONS.TOO_SHORT_DIGITS, values: { limit } }
      : VALIDATIONS.OK;

export const maxLengthDigits =
  (limit: number) =>
  (value: string | boolean = '') =>
    typeof value === 'string' && value.length > limit
      ? { key: VALIDATIONS.TOO_LONG_DIGITS, values: { limit } }
      : VALIDATIONS.OK;

export const isValidDomain = (value: string) =>
  !RegExp(/^(?!-|.*[-]{2,}.*|.*-$)[a-z0-9-]{2,}$/).test(value)
    ? VALIDATIONS.NOT_VALID_DOMAIN
    : VALIDATIONS.OK;

export const isValidPhoneNumber = (value: string | boolean = '') =>
  typeof value === 'string' &&
  value.length > 0 &&
  !RegExp(
    /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*?((?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))*$/,
  ).test(String(value))
    ? VALIDATIONS.NOT_VALID_PHONE
    : VALIDATIONS.OK;

export const isValidEmail = (value: string | boolean = '') => {
  if (typeof value === 'string') {
    return validateEmail(value) ? VALIDATIONS.OK : VALIDATIONS.NOT_VALID_EMAIL;
  }
  return VALIDATIONS.NOT_VALID_EMAIL;
};
