import { GlobalStyles } from '@mui/material';
import '../assets/fonts/fonts.css';

export default () => (
  <GlobalStyles
    styles={(theme) => {
      return {
        '*': {
          boxSizing: 'border-box',
        },
        body: {
          margin: 0,
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
        },
        ['html, body, #root']: {
          height: '100%',
        },
      };
    }}
  />
);
