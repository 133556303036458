import { Stack } from '@mui/material';

// shared across registration app and Elis as background during onboarding
const BG_1920 =
  'https://rossum-public-resources.app.rossum.ai/images/app_blur_1920.png';
const BG_1440 =
  'https://rossum-public-resources.app.rossum.ai/images/app_blur_1440.png';

type PageWrapperProps = {
  children: React.ReactNode;
  withBackground?: boolean;
};

const PageWrapper = ({
  children,
  withBackground = false,
}: PageWrapperProps) => (
  <Stack
    alignItems="center"
    sx={{
      paddingBottom: '40px',
      px: 4,
      height: '100%',
      width: '100%',
      backgroundImage: withBackground ? `url(${BG_1440})` : undefined,
      '@media only screen and (min-width: 1440px)': {
        backgroundImage: withBackground ? `url(${BG_1920})` : undefined,
      },
      backgroundSize: 'cover',
      paddingTop: '120px',
    }}
  >
    {children}
  </Stack>
);

export default PageWrapper;
