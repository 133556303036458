import { values } from 'lodash';
import { getErrorMessage } from './getErrorMessage';

export function validateFields<FieldsT>(
  fields: FieldsT,
  onSuccess: (e?: React.FormEvent<HTMLFormElement>) => void,
) {
  const errors = values(fields).map((field) => {
    const errorMessage = getErrorMessage(
      field.inputProps.value,
      field.validations,
    );

    field.handlers.setTouched(true);
    field.handlers.setError(errorMessage);

    return errorMessage;
  });

  const hasErrors = ([] as Array<string>)
    .concat(...errors)
    .some((e: string) => e !== '');

  if (hasErrors) {
    return;
  }

  return onSuccess();
}
