import { styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { GlobalError } from '../types';

const GlobalErrorWrapper = styled('div')(() => ({
  paddingLeft: 15,
  paddingRight: 15,
  marginTop: 15,
  marginBottom: 20,
  position: 'relative',
  textAlign: 'center',
  top: 0,
  width: '100%',
}));

const Inner = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.text.disabled,
  borderRadius: 5,
  display: 'inline-flex',
  overflow: 'hidden',
}));

const Status = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  padding: '5px 10px',
}));

const Message = styled('span')({
  padding: '5px 10px',
});

interface Props {
  globalError: GlobalError;
}

export default ({ globalError: { status, message } }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <GlobalErrorWrapper data-testid="globalError">
      <Inner>
        <Status>
          <Typography variant="caption">{status || 'Error'}</Typography>
        </Status>
        <Message>
          <Typography variant="caption">{message}</Typography>
        </Message>
      </Inner>
    </GlobalErrorWrapper>
  );
};
