import { first } from 'lodash';
import { useEffect, useState } from 'react';
import { noop, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { checkDomain } from '../../../api/requests';
import { translations } from '../../../en';
import { CheckFunction, SetStateAction } from '../../../types';

const handleDomainCheck = (
  domain: string,
  setError: SetStateAction<string | null>,
): Observable<boolean> => {
  return checkDomain({ domain }).pipe(
    map(() => true),
    catchError((e) => {
      setError(
        first(e.response?.domain) || translations.registration.form.error,
      );
      return of(false);
    }),
  );
};

export const useDomainCheckHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [check, setCheck] = useState<(domain: string) => void>(
    () => () => noop,
  );
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const subject = new Subject<string>();
    setCheck((): CheckFunction => (body) => subject.next(body));

    const subscription = subject
      .asObservable()
      .pipe(
        filter((domain) => !!domain.length),
        tap(() => {
          setLoading(true);
          setError(null);
        }),
        debounceTime(500),
        switchMap((domain) => handleDomainCheck(domain, setError)),
        tap(() => setLoading(false)),
      )
      .subscribe((responseValid) => setValid(responseValid));

    return () => {
      subscription.unsubscribe();
      subject.complete();
    };
  }, []);

  return {
    check,
    error,
    loading,
    valid,
  };
};
