export const slugify = (text: string) => {
  return (
    text
      .toString()
      .normalize('NFKD')
      .toLowerCase()
      .trim()
      // Replace spaces and underscores with dashes
      .replace(/[\s_]+/g, '-')
      // Remove invalid characters
      .replace(/[^0-9a-z\-]+/g, '')
      // Remove double dashes
      .replace(/\-\-+/g, '-')
      // Remove dash at the beginning
      .replace(/^-/, '')
      // Remove dash at the end
      .replace(/-$/, '')
  );
};
