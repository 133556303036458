import { useState } from 'react';
import { STEPS, StepT } from '../../config/values';
import { translations } from '../../en';
import Header from '../../ui/Header';
import PageWrapper from '../../ui/PageWrapper';
import RegistrationForm from './partials/RegistrationForm';

const Page = () => {
  const [activeStep, setActiveStep] = useState<StepT>(STEPS[0]);

  return (
    <PageWrapper withBackground={true}>
      <Header title={translations.registration.header.circleAlt} />
      <RegistrationForm activeStep={activeStep} setActiveStep={setActiveStep} />
    </PageWrapper>
  );
};

export default Page;
