import { FormHelperTextProps, ThemeProvider } from '@mui/material';
import { resolveTheme } from '@rossum/rossum-ui/theme';
import get from 'lodash/get';
import { ErrorInfo, PureComponent } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { envValues } from './config/envValues';
import { initGTM } from './lib/gtm';
import initRollbar from './lib/rollbar';
import initSmartlook from './lib/smartlook';
import EmailLoginPage from './pages/emailLogin';
import RegistrationPage from './pages/registration';
import GlobalStyles from './ui/GlobalStyles';

const initialScripts = () => {
  initGTM();

  initSmartlook();

  // Rollbar Initialization
  if (envValues.shouldRollbar) {
    initRollbar();
  }

  window.onerror = (...errors) => {
    const rollbar = get(window, 'Rollbar');
    if (rollbar) {
      rollbar.error(...errors);
    }
  };
};

initialScripts();

class App extends PureComponent {
  componentDidCatch(error: Error, info: ErrorInfo) {
    const rollbar = get(window, 'Rollbar');
    if (rollbar) {
      rollbar.error(error, { info });
    }
  }

  render() {
    return (
      <ThemeProvider
        theme={resolveTheme('dark', {
          components: {
            MuiFormHelperText: {
              defaultProps: { 'data-testid': 'errorMessage' } as Partial<
                FormHelperTextProps<'p', { 'data-testid': string }>
              >,
            },
          },
        })}
      >
        <GlobalStyles />
        <BrowserRouter>
          <Routes>
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/" element={<EmailLoginPage />} />
            <Route path="*" element={<Navigate to="/" replace={true} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}

export default App;
