import { Stack, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const Wrapper = styled('div')({
  display: 'flex',
  height: 20,
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
});

const Content = styled('div')({
  display: 'flex',
  margin: 0,
  position: 'absolute',
});

const Dot = styled('div')<{ active: boolean }>(({ active }) => ({
  opacity: active ? 1 : 0,
  transition: 'opacity 0.25s',
  ['&:after']: {
    content: '"."',
  },
}));

const LoadingText = ({ text, visible }: { text: string; visible: boolean }) => {
  const [dotCount, setDotCount] = useState(0);

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        setDotCount((prevState) => (prevState === 3 ? 0 : prevState + 1));
      }, 350);

      return () => {
        clearInterval(interval);
      };
    }
  }, [visible]);

  return (
    <Wrapper>
      {visible && (
        <Content>
          <Typography variant="body2" component="div">
            <Stack direction="row">
              {text}
              {[...Array(3)].map((_, i) => {
                return <Dot active={i < dotCount} key={i} />;
              })}
            </Stack>
          </Typography>
        </Content>
      )}
    </Wrapper>
  );
};

export default LoadingText;
