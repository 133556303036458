import get from 'lodash/get';
import { boolify } from '../utils/helpers';

const apiUrl =
  get(window, ['env', 'REACT_APP_API_URL']) ||
  process.env.REACT_APP_API_URL ||
  'http://localhost:8000';

const createKey =
  get(window, ['env', 'REACT_APP_CREATE_KEY']) ||
  process.env.REACT_APP_CREATE_KEY ||
  '';

const rollbarEnvironment =
  get(window, ['env', 'REACT_APP_ROLLBAR_ENVIRONMENT']) ||
  process.env.REACT_APP_ROLLBAR_ENVIRONMENT;

const shouldRollbar = boolify(
  get(window, ['env', 'REACT_APP_ENABLE_ROLLBAR']) ||
    process.env.REACT_APP_ENABLE_ROLLBAR,
);

const customerDomain = boolify(
  get(window, ['env', 'REACT_APP_CUSTOMER_DOMAIN']) ||
    process.env.REACT_APP_CUSTOMER_DOMAIN,
);

export const envValues = {
  apiUrl,
  createKey,
  rollbarEnvironment,
  shouldRollbar,
  customerDomain,
};
