import { VALIDATIONS } from './types';

export const translations = {
  emailLogin: {
    redirecting: 'You are being redirected to your account',
    error: 'Something went wrong. Please try again later.',
    input: {
      label: 'Enter your email',
      placeholder: 'Enter your email',
    },
    header: {
      title: 'Welcome to Rossum',
      subTitle: 'Log in via email',
    },
    emailSent: {
      title: 'The email with log in instruction has been successfully sent',
      message:
        'Please check your email inbox for the email. Can’t find it? Check your spam folder.',
      problem: 'Any trouble? Contact us on',
      helpLink: 'support@rossum.ai',
    },
    sendingEmail: 'Sending e-mail with login information',
    submit: 'Log in',
    submitDescription: 'We’ll send you an email with login information.',
    goToRegistration: 'Don’t have an account? Sign up',
  },
  registration: {
    footer: {
      alreadyRegistered: 'Already have an account? Log in.',
      signIn: 'Log in',
    },
    form: {
      buttons: {
        next: 'Next',
        submit: 'Get started!',
      },
      fields: {
        company: 'Company*',
        domain: 'Domain*',
        email: 'Work Email*',
        firstName: 'First Name*',
        lastName: 'Last Name*',
        marketingCommunicationAccepted:
          'Yes, Rossum can email me and process my data for marketing purposes.',
        password: 'Password*',
        phoneNumber: 'Phone Number (intl)',
        privacyLink: 'Privacy Policy',
        termsAccepted: 'I agree with',
        termsLink: 'Rossum terms',
        redirect: 'Redirecting to Rossum App',
      },
      error: 'Something went wrong. Please try again later.',
    },
    header: {
      circleAlt: 'Get started with Rossum',
      getStarted: 'Get started with',
      productName: 'Rossum',
    },
    steps: {
      account: {
        title: 'Create your account',
      },
      personalInfo: {
        title: 'Tell us something about you',
      },
    },
  },
  passwordScore: {
    weak: 'Weak',
    soSo: 'So-so',
    good: 'Good',
    strong: 'Strong',
  },
  validations: {
    [VALIDATIONS.NOT_VALID_PHONE]: 'Please enter a valid phone number',
    [VALIDATIONS.NOT_VALID_EMAIL]: 'Please enter a valid e-mail address',
    [VALIDATIONS.NOT_VALID_DOMAIN]: 'Invalid domain format',
    [VALIDATIONS.REQUIRED]: 'This field is required',
    [VALIDATIONS.REQUIRED_FIRST_NAME]: 'Please enter your first name',
    [VALIDATIONS.REQUIRED_LAST_NAME]: 'Please enter your last name',
    [VALIDATIONS.REQUIRED_COMPANY_NAME]: 'Please enter your company name',
    [VALIDATIONS.REQUIRED_DOMAIN]: 'Please enter domain',
    [VALIDATIONS.REQUIRED_EMAIL]: 'Please enter your business e-mail',
    [VALIDATIONS.LOGIN_EMAIL]: 'Please enter your Rossum account e-mail',
    [VALIDATIONS.REQUIRED_PASSWORD]: 'Please enter your password',
    [VALIDATIONS.REQUIRED_EMAIL_FOR_PASSWORD]: 'Missing e-mail',

    [VALIDATIONS.TERMS_REQUIRED]: 'You have to agree with Rossum terms',
    [VALIDATIONS.TOO_SHORT]: ({ limit }: { limit: number }) =>
      `Enter at least ${limit} characters`,
    [VALIDATIONS.TOO_SHORT_DIGITS]: ({ limit }: { limit: number }) =>
      `Enter at least ${limit} digits`,
    [VALIDATIONS.TOO_LONG_DIGITS]: ({ limit }: { limit: number }) =>
      `Enter at most ${limit} digits`,
  },
};
