import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { Logo } from '@rossum/rossum-ui/Logo';

const Header = ({ title, ...typographyProps }: { title: string }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{ alignItems: 'center', justifyContent: 'center' }}
  >
    <Logo />
    <Typography variant="h3" {...typographyProps}>
      {title}
    </Typography>
  </Stack>
);

export default Header;
