import { CSSObject, keyframes } from '@emotion/css';
import { styled } from '@mui/material';
import { Theme } from '@mui/material';

export enum LOADER_COLOR {
  LIGHT,
  DARK,
}
export enum LOADER_SIZE {
  REGULAR,
}

type LoaderColorsMap = { [key in LOADER_COLOR]: (t: Theme) => CSSObject };
type LoaderSizesMap = { [key in LOADER_SIZE]: CSSObject };

const loaderColors: LoaderColorsMap = {
  [LOADER_COLOR.DARK]: (t) => {
    return { borderTopColor: t.palette.text.secondary };
  },
  [LOADER_COLOR.LIGHT]: (t) => {
    return { borderTopColor: t.palette.secondary.main };
  },
};

const loaderSizes: LoaderSizesMap = {
  [LOADER_SIZE.REGULAR]: { width: 24, height: 24, borderWidth: 2 },
};

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const SpinnerLoader = styled('div')<{
  loaderColor?: LOADER_COLOR;
  size?: LOADER_SIZE;
}>(
  () => ({
    animation: `${spin} .7s linear infinite`,
    borderColor: 'transparent',
    borderRadius: '50%',
    borderStyle: 'solid',
    margin: '0 auto',
  }),
  ({ loaderColor = LOADER_COLOR.LIGHT, theme }) =>
    loaderColors[loaderColor](theme),
  ({ size = LOADER_SIZE.REGULAR }) => loaderSizes[size],
);

export default SpinnerLoader;
