import { styled } from '@mui/material';

interface Props {
  underlinedOnHover?: boolean;
}

export default styled('a')<Props>(({ underlinedOnHover }) => ({
  color: 'inherit',
  textDecoration: underlinedOnHover ? 'none' : 'underline',
  [':hover']: {
    textDecoration: underlinedOnHover ? 'underline' : 'none',
  },
}));
