export const STEPS = ['personalInfo', 'account'] as const;
export type StepT = typeof STEPS[number];

export const PHONE_NUMBER_MIN_LENGTH = 7;
export const PHONE_NUMBER_MAX_LENGTH = 32;
export const DOMAIN_MIN_LENGTH = 2;

export const emptySchemaTemplate = 'Empty Organization Template';

export const currentCodeVersion = document.querySelector<HTMLMetaElement>(
  'meta[name="GIT_COMMIT"]',
)?.content;
