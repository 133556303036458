import invoke from 'lodash/invoke';
import { useEffect, useRef, useState } from 'react';
import { StepT } from '../config/values';

const getDomain = (value: string | boolean = '') => {
  const domain = typeof value === 'string' ? value.split('@') : null;
  if (domain && domain[1]) {
    return domain[1];
  }
  return '';
};

const pushToDataLayer = (data: RegistrationEvent | GAEvent) =>
  invoke(window, 'dataLayer.push', data);

export type FormField =
  | 'firstName'
  | 'lastName'
  | 'company'
  | 'tel'
  | 'domain'
  | 'email'
  | 'password'
  | 'terms-conditions-checkbox'
  | 'marketing-consent-checkbox'
  | 'login-email';

export type InputValue = string | number | Array<string> | boolean | undefined;

type ErrorEventData = {
  field: FormField;
  value: InputValue;
  errorMessage: string;
};

type ErrorMessageEventData = {
  category: 'error message';
} & ErrorEventData;

const transformFieldValues: Partial<
  Record<FormField, (value: InputValue) => string>
> = {
  email: (value) => getDomain(`${value}`),
  password: () => '*****',
};

export const trackError = (eventData: ErrorEventData) => {
  const value =
    transformFieldValues[eventData.field]?.(eventData.value) || eventData.value;

  pushToDataLayer({
    event: 'elis-registration',
    eventData: {
      category: 'error message',
      ...eventData,
      value,
    },
  });
};

type NavigationEventData = {
  category: 'navigation';
  value: 'next' | 'previous';
};

const trackNavigation = (direction: NavigationEventData['value']) => {
  pushToDataLayer({
    event: 'elis-registration',
    eventData: {
      category: 'navigation',
      value: direction,
    },
  });
};

type RegistrationEvent = {
  event: 'elis-registration';
  eventData: ErrorMessageEventData | NavigationEventData;
};

// GA tracking
export const appViewed = '00-View';
export const nameSubmitted = '02-Name submitted';
export const passwordSubmitted = '03-Password submitted';

export const dataLayerValuesForSteps: { [step in StepT]: GAEventDataT } = {
  account: {
    eAct: nameSubmitted,
  },
  personalInfo: {
    eAct: appViewed,
    eNonInteraction: true,
  },
};

type eActT = typeof appViewed | typeof nameSubmitted | typeof passwordSubmitted;

export interface GAEventDataT {
  eAct: eActT;
  eNonInteraction?: boolean;
  eExt1?: string;
}

type GAEvent = {
  event: 'gaEvent';
  eventCallback: () => void;
  gaEventData: {
    eCat: 'Registration Funnel';
    eLab: '02-Registration';
    eNonInteraction: boolean;
    eVal: 0;
  } & GAEventDataT;
};

export const pushGAEvent = (eventData: GAEventDataT) =>
  pushToDataLayer({
    event: 'gaEvent',
    eventCallback: () => {
      invoke(window, 'dataLayer.push', {
        gaEventData: undefined,
      });
    },
    gaEventData: {
      eCat: 'Registration Funnel',
      eLab: '02-Registration',
      eNonInteraction: false,
      eVal: 0,
      ...eventData,
    },
  });

export const useDataLayerHook = (activeStep: StepT) => {
  const [didReport, setDidReport] = useState<
    Partial<{ [stepIndex in StepT]: boolean }>
  >({});

  if (window.dataLayer && !didReport[activeStep]) {
    pushGAEvent(dataLayerValuesForSteps[activeStep]);
    setDidReport({ ...didReport, [activeStep]: true });
  }
};

export const useTrackNavigationHook = (activeStepIndex: number) => {
  const prevStepIndex = useRef<number | null>(null);
  useEffect(() => {
    if (prevStepIndex.current !== null) {
      trackNavigation(
        activeStepIndex > prevStepIndex.current ? 'next' : 'previous',
      );
    }

    prevStepIndex.current = activeStepIndex;
  }, [activeStepIndex]);
};
