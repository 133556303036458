import { envValues } from '../../../config/envValues';
import { emptySchemaTemplate } from '../../../config/values';
import { FormValues, OrganizationCreateRequestBody } from '../../../types';

export const mapFormValuesToOrganizationCreate = (
  formValues: FormValues,
): OrganizationCreateRequestBody => ({
  communication_opt_in: formValues.marketingCommunicationAccepted,
  create_key: envValues.createKey,
  ...(envValues.customerDomain && { domain: formValues.domain }),
  organization_name: formValues.company,
  phone_number: formValues.phoneNumber.length
    ? formValues.phoneNumber
    : undefined,
  template_name: emptySchemaTemplate,
  user_email: formValues.email,
  user_fullname: `${formValues.firstName} ${formValues.lastName}`,
  user_password: formValues.password,
  user_ui_settings: {
    hints: {},
    locale: 'en',
    isUsingNewDashboard: true,
  },
});
