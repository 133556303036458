import get from 'lodash/get';
import includes from 'lodash/includes';
import { ValidationType } from '../types';

export const boolify = (maybeString: string) =>
  typeof maybeString === 'string'
    ? includes(maybeString, 'true')
    : !!maybeString;

export const getValidationKey = (validation: ValidationType) =>
  typeof validation === 'string' ? validation : get(validation, 'key');
