import { useEffect, useState } from 'react';
import { noop, Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { loginInfo } from '../../../api/requests';
import { translations } from '../../../en';
import { LoginInfoFunction, SetStateAction } from '../../../types';

const handleEmailCheck = (
  email: string,
  setError: SetStateAction<string | null>,
): Observable<boolean> => {
  return loginInfo({ email }).pipe(
    map(() => true),
    catchError(() => {
      setError(translations.emailLogin.error);
      return of(false);
    }),
  );
};

export const useLoginInfoHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [submit, setSubmit] = useState<(email: string) => void>(
    () => () => noop,
  );
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    const subject = new Subject<string>();
    setSubmit((): LoginInfoFunction => (body) => subject.next(body));

    const subscription = subject
      .asObservable()
      .pipe(
        tap(() => {
          setLoading(true);
          setError(null);
        }),
        switchMap((email) => handleEmailCheck(email, setError)),
        tap(() => setLoading(false)),
      )
      .subscribe((emailHasBeenSent) => setEmailSent(emailHasBeenSent));

    return () => {
      subscription.unsubscribe();
      subject.complete();
    };
  }, []);

  return {
    submit,
    error,
    loading,
    emailSent,
  };
};
