import { useEffect, useState } from 'react';
import { noop, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { createOrganization } from '../../../api/requests';
import { setRossumAppDomainCookie } from '../../../features/appDomain';
import { FormValues, ResponseType, SubmitFunction } from '../../../types';
import { passwordSubmitted, pushGAEvent } from '../../../utils/tracking';
import { mapFormValuesToOrganizationCreate } from '../helpers/mappers';

type HandleTokenAndRedirectPayloadT = {
  token: string;
  email: string;
  domain: string;
  code: string;
};

const handleTokenAndRedirect = (
  payload: HandleTokenAndRedirectPayloadT | void,
) => {
  if (payload) {
    pushGAEvent({ eAct: passwordSubmitted, eExt1: payload.email });
    setRossumAppDomainCookie(payload.domain);

    window.location.replace(
      `https://${payload.domain}#authCode=${payload.code}`,
    );
  }
};

const handleOrganizationCreate = (
  formValues: FormValues,
  onErrorCallback: (e: ResponseType) => void,
): Observable<HandleTokenAndRedirectPayloadT | void> => {
  const organizationBody = mapFormValuesToOrganizationCreate(formValues);

  return createOrganization(organizationBody).pipe(
    map(({ response: { key: token, domain, code } }) => ({
      token,
      email: organizationBody.user_email,
      domain,
      code,
    })),
    catchError((e) => of(onErrorCallback(e))),
  );
};

export const useSubmitRegistrationFormHook = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ResponseType>(null);
  const [submit, setSubmit] = useState<SubmitFunction>(() => () => noop);

  useEffect(() => {
    const subject = new Subject<FormValues>();
    setSubmit((): SubmitFunction => (body) => subject.next(body));

    const subscription = subject
      .asObservable()
      .pipe(
        tap(() => {
          setLoading(true);
          setError(null);
        }),
        mergeMap((formValues) =>
          handleOrganizationCreate(formValues, (e) => {
            setError(e);
            setLoading(false);
          }),
        ),
      )
      .subscribe(handleTokenAndRedirect);

    return () => {
      subscription.unsubscribe();
      subject.complete();
    };
  }, []);

  return {
    error,
    loading,
    submit,
  };
};
