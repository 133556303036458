import { useEffect, useState } from 'react';
import { noop, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { checkPasswordScore } from '../../../api/requests';
import {
  CheckPasswordScoreRequestBody,
  PasswordScoreCheckFunction,
  PasswordScoreCheckResponse,
  ResponseType,
  SetStateAction,
} from '../../../types';

const handlePasswordScoreCheck = (
  payload: CheckPasswordScoreRequestBody,
  setApiError: SetStateAction<ResponseType | null>,
): Observable<PasswordScoreCheckResponse> => {
  return checkPasswordScore(payload).pipe(
    map(({ response }) => response),
    catchError((e) => {
      setApiError(e || null);
      return of(null);
    }),
  );
};

export const usePasswordScoreHook = () => {
  const [passwordScoreCheck, setPasswordScoreCheck] = useState<
    (payload: CheckPasswordScoreRequestBody) => void
  >(() => () => noop);
  const [data, setData] = useState<PasswordScoreCheckResponse>(null);
  const [apiError, setApiError] = useState<ResponseType | null>(null);

  useEffect(() => {
    const subject = new Subject<CheckPasswordScoreRequestBody>();
    setPasswordScoreCheck(
      (): PasswordScoreCheckFunction => (body) => subject.next(body),
    );

    const subscription = subject
      .pipe(
        tap(() => {
          setApiError(null);
        }),
        debounceTime(100),
        switchMap(({ password, email }) =>
          handlePasswordScoreCheck({ password, email }, setApiError),
        ),
      )
      .subscribe((response) => setData(response));

    return () => {
      subscription.unsubscribe();
      subject.complete();
    };
  }, []);

  return {
    passwordScoreCheck,
    apiError,
    data,
  };
};
