import { Typography } from '@mui/material';
import React from 'react';
import { StepT } from '../../../config/values';
import { translations } from '../../../en';

interface Props {
  activeStep: StepT;
}

const ActiveStepTitle = ({ activeStep }: Props) => {
  const text = translations.registration.steps[activeStep];
  if (!text) {
    return null;
  }

  return (
    <Typography
      variant="h5"
      color="text.secondary"
      data-testid="active-step-title"
    >
      {text.title}
    </Typography>
  );
};

export default ActiveStepTitle;
