import {
  Button,
  ButtonBaseActions,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import omit from 'lodash/omit';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { envValues } from '../../../config/envValues';
import {
  DOMAIN_MIN_LENGTH,
  PHONE_NUMBER_MAX_LENGTH,
  PHONE_NUMBER_MIN_LENGTH,
  STEPS,
  StepT,
} from '../../../config/values';
import { translations } from '../../../en';
import { Form, useCreateField } from '../../../features/form';
import {
  FormValues,
  GlobalError as GlobalErrorType,
  SetStateAction,
  VALIDATIONS,
} from '../../../types';
import GlobalError from '../../../ui/GlobalError';
import Link from '../../../ui/Link';
import LoadingText from '../../../ui/LoadingText';
import SpinnerLoader from '../../../ui/SpinnerLoader';
import {
  useDataLayerHook,
  useTrackNavigationHook,
} from '../../../utils/tracking';
import {
  isValidDomain,
  isValidEmail,
  isValidPhoneNumber,
  maxLengthDigits,
  minLength,
  minLengthDigits,
  required,
  requiredConditions,
} from '../../../utils/validations';
import { handleApiErrors } from '../helpers/handleApiErrors';
import { slugify } from '../helpers/slugify';
import { useDomainCheckHook } from '../hooks/useDomainCheckHook';
import useHashToFields from '../hooks/useHashToFields';
import { usePasswordScoreHook } from '../hooks/usePasswordScoreHook';
import { useSubmitRegistrationFormHook } from '../hooks/useSubmitRegistrationHook';
import ActiveStepTitle from '../ui/ActiveStepTitle';
import DomainInput from './DomainInput';
import PasswordValidationField from './PasswordValidationField';

type RegistrationFormProps = {
  activeStep: StepT;
  setActiveStep: SetStateAction<StepT>;
};

const RegistrationForm = ({
  activeStep,
  setActiveStep,
}: RegistrationFormProps) => {
  useDataLayerHook(activeStep);
  useTrackNavigationHook(STEPS.indexOf(activeStep));

  const { loading, error, submit } = useSubmitRegistrationFormHook();
  const {
    check: checkDomain,
    valid: domainIsValid,
    error: domainError,
    loading: domainIsLoading,
  } = useDomainCheckHook();

  const { passwordScoreCheck, data: passwordScore } = usePasswordScoreHook();

  const [globalError, setGlobalError] = useState<GlobalErrorType | null>(null);

  const { valuesFromUrl } = useHashToFields({ setActiveStep });

  const firstName = useCreateField<string>(
    { defaultValue: valuesFromUrl.firstName || '', fieldName: 'firstName' },
    required(VALIDATIONS.REQUIRED_FIRST_NAME),
  );
  const lastName = useCreateField<string>(
    { defaultValue: valuesFromUrl.lastName || '', fieldName: 'lastName' },
    required(VALIDATIONS.REQUIRED_LAST_NAME),
  );
  const company = useCreateField<string>(
    { defaultValue: valuesFromUrl.companyName || '', fieldName: 'company' },
    required(VALIDATIONS.REQUIRED_COMPANY_NAME),
  );
  const domain = useCreateField<string>(
    { defaultValue: slugify(valuesFromUrl.companyName), fieldName: 'domain' },
    required(VALIDATIONS.REQUIRED_DOMAIN),
    minLength(DOMAIN_MIN_LENGTH),
    isValidDomain,
  );
  const phoneNumber = useCreateField<string>(
    { defaultValue: valuesFromUrl.phoneNumber || '', fieldName: 'tel' },
    minLengthDigits(PHONE_NUMBER_MIN_LENGTH),
    maxLengthDigits(PHONE_NUMBER_MAX_LENGTH),
    isValidPhoneNumber,
  );
  const email = useCreateField<string>(
    { defaultValue: valuesFromUrl.workEmail || '', fieldName: 'email' },
    required(VALIDATIONS.REQUIRED_EMAIL),
    isValidEmail,
  );
  const password = useCreateField<string>(
    { defaultValue: '', fieldName: 'password' },
    required(VALIDATIONS.REQUIRED_PASSWORD),
  );

  const displayMarketingCheckbox = !valuesFromUrl.communicationOptIn;

  const termsAcceptedInMKTO = valuesFromUrl.termsAccepted === 'yes';

  const termsAccepted = useCreateField<boolean>(
    {
      defaultValue: termsAcceptedInMKTO,
      fieldName: 'terms-conditions-checkbox',
    },
    requiredConditions,
  );
  const marketingCommunicationAccepted = useCreateField<boolean>({
    defaultValue: valuesFromUrl.communicationOptIn === 'yes',
    fieldName: 'marketing-consent-checkbox',
  });

  const formValues: FormValues = {
    company: company.inputProps.value,
    ...(envValues.customerDomain && { domain: domain.inputProps.value }),
    email: email.inputProps.value,
    firstName: firstName.inputProps.value,
    lastName: lastName.inputProps.value,
    marketingCommunicationAccepted:
      marketingCommunicationAccepted.inputProps.value,
    password: password.inputProps.value,
    phoneNumber: phoneNumber.inputProps.value,
    termsAccepted: termsAccepted.inputProps.value,
  };

  useEffect(
    handleApiErrors(
      error,
      {
        user_email: (e) =>
          email.handlers.setApiError(
            `${e}${e === 'Email already used.' ? ' Try to log in.' : ''}`,
          ),
        user_password: password.handlers.setApiError,
        domain: domain.handlers.setApiError,
      },
      setGlobalError,
      { formValues: omit(formValues, 'password') },
    ),
    [error],
  );

  const nextStep = () =>
    setActiveStep((current) => STEPS[STEPS.indexOf(current) + 1]);

  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const loginActionRef = useRef<ButtonBaseActions>(null);

  useEffect(() => {
    if (email.inputProps.error.includes('Email already used.')) {
      loginButtonRef.current?.focus();
      loginActionRef.current?.focusVisible();
    }
  }, [email.inputProps.error]);

  return (
    <>
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          pt: 1,
          position: 'relative',
          width: '100%',
        }}
      >
        {globalError && <GlobalError globalError={globalError} />}
        <Stack sx={{ maxWidth: 330, width: '100%' }}>
          {activeStep === 'personalInfo' && (
            <Form
              fields={{ company, phoneNumber, firstName, lastName }}
              onSubmit={nextStep}
            >
              {({ fields }) => (
                <Stack spacing={4} alignItems="center">
                  <ActiveStepTitle activeStep={activeStep} />
                  <Stack spacing={2} sx={{ width: '100%' }}>
                    <TextField
                      size="small"
                      onChange={fields.firstName.inputProps.onChange}
                      onBlur={fields.firstName.inputProps.onBlur}
                      value={fields.firstName.inputProps.value}
                      error={fields.firstName.inputProps.hasError}
                      helperText={fields.firstName.inputProps.error}
                      data-testid="firstName"
                      placeholder={
                        translations.registration.form.fields.firstName
                      }
                      label={translations.registration.form.fields.firstName}
                    />
                    <TextField
                      size="small"
                      onChange={fields.lastName.inputProps.onChange}
                      onBlur={fields.lastName.inputProps.onBlur}
                      value={fields.lastName.inputProps.value}
                      error={fields.lastName.inputProps.hasError}
                      helperText={fields.lastName.inputProps.error}
                      data-testid="lastName"
                      placeholder={
                        translations.registration.form.fields.lastName
                      }
                      label={translations.registration.form.fields.lastName}
                    />
                    <TextField
                      size="small"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        fields.company.inputProps.onChange(event);
                        // Store suggessted domain in state on change.
                        domain.handlers.setValue(slugify(event.target.value));
                      }}
                      onBlur={fields.company.inputProps.onBlur}
                      value={fields.company.inputProps.value}
                      error={fields.company.inputProps.hasError}
                      helperText={fields.company.inputProps.error}
                      data-testid="company"
                      placeholder={
                        translations.registration.form.fields.company
                      }
                      label={translations.registration.form.fields.company}
                    />
                    <TextField
                      size="small"
                      onChange={fields.phoneNumber.inputProps.onChange}
                      onBlur={fields.phoneNumber.inputProps.onBlur}
                      value={fields.phoneNumber.inputProps.value}
                      error={fields.phoneNumber.inputProps.hasError}
                      helperText={fields.phoneNumber.inputProps.error}
                      type="tel"
                      data-testid="tel"
                      placeholder={
                        translations.registration.form.fields.phoneNumber
                      }
                      label={translations.registration.form.fields.phoneNumber}
                    />
                  </Stack>
                  <Button
                    type="submit"
                    variant="contained"
                    data-testid="next"
                    sx={{ width: '100%' }}
                  >
                    <Typography variant="button">
                      {translations.registration.form.buttons.next}
                    </Typography>
                  </Button>
                </Stack>
              )}
            </Form>
          )}

          {activeStep === 'account' && (
            <Form
              fields={{
                email,
                marketingCommunicationAccepted,
                ...(envValues.customerDomain && { domain }),
                password,
                termsAccepted,
              }}
              onSubmit={() => {
                if (envValues.customerDomain) {
                  if (!domain.inputProps.hasError && !domainIsLoading) {
                    submit(formValues);
                  }

                  return;
                }

                submit(formValues);
              }}
            >
              {({ fields }) => (
                <Stack spacing={2}>
                  <Stack spacing={3}>
                    <Stack
                      sx={{ width: '100%' }}
                      spacing={4}
                      alignItems="center"
                    >
                      <ActiveStepTitle activeStep={activeStep} />
                      <Stack spacing={2} sx={{ width: '100%' }}>
                        <TextField
                          onChange={fields.email.inputProps.onChange}
                          onBlur={fields.email.inputProps.onBlur}
                          value={fields.email.inputProps.value}
                          error={fields.email.inputProps.hasError}
                          helperText={fields.email.inputProps.error}
                          data-testid="email"
                          autoFocus={!valuesFromUrl.workEmail}
                          id="email-field"
                          placeholder={
                            translations.registration.form.fields.email
                          }
                          size="small"
                          label={translations.registration.form.fields.email}
                          disabled={
                            loading ||
                            (!!valuesFromUrl.workEmail &&
                              !fields.email.inputProps.hasError &&
                              valuesFromUrl.workEmail ===
                                fields.email.inputProps.value)
                          }
                        />
                        <PasswordValidationField
                          field={fields.password}
                          checkPasswordStrength={passwordScoreCheck}
                          passwordScore={passwordScore}
                          disabled={loading}
                          autoFocus={!!valuesFromUrl.workEmail}
                          email={email.inputProps.value}
                        />
                        {envValues.customerDomain && fields.domain && (
                          <DomainInput
                            field={fields.domain}
                            checkDomain={checkDomain}
                            isLoading={domainIsLoading}
                            isValid={domainIsValid}
                            disabled={loading}
                            error={domainError}
                          />
                        )}
                        {!termsAcceptedInMKTO && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={fields.termsAccepted.inputProps.value}
                                id="termsAndConditionsCheckbox"
                                data-testid="terms-conditions-checkbox"
                                disabled={loading}
                                inputProps={{
                                  onChange:
                                    fields.termsAccepted.inputProps.onChange,
                                  onBlur:
                                    fields.termsAccepted.inputProps.onBlur,
                                }}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                {
                                  translations.registration.form.fields
                                    .termsAccepted
                                }{' '}
                                <Link
                                  href="https://rossum.ai/terms"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  tabIndex={-1}
                                >
                                  {
                                    translations.registration.form.fields
                                      .termsLink
                                  }
                                </Link>
                              </Typography>
                            }
                          />
                        )}
                        {fields.termsAccepted.inputProps.hasError ? (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ m: '0 !important', pt: 0, pl: 2 }}
                            data-testid="errorMessage"
                          >
                            {fields.termsAccepted.inputProps.error}
                          </Typography>
                        ) : null}
                        {displayMarketingCheckbox && (
                          <FormControlLabel
                            sx={{ mt: '4px !important' }}
                            control={
                              <Checkbox
                                checked={
                                  fields.marketingCommunicationAccepted
                                    .inputProps.value
                                }
                                id="marketingCommunicationCheckbox"
                                data-testid="marketing-consent-checkbox"
                                disabled={loading}
                                inputProps={{
                                  onChange:
                                    fields.marketingCommunicationAccepted
                                      .inputProps.onChange,
                                  onBlur:
                                    fields.marketingCommunicationAccepted
                                      .inputProps.onBlur,
                                }}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                {
                                  translations.registration.form.fields
                                    .marketingCommunicationAccepted
                                }{' '}
                                <Link
                                  href="https://rossum.ai/terms"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  tabIndex={-1}
                                >
                                  (
                                  {
                                    translations.registration.form.fields
                                      .privacyLink
                                  }
                                  )
                                </Link>
                              </Typography>
                            }
                          />
                        )}
                        {fields.marketingCommunicationAccepted.inputProps
                          .hasError ? (
                          <Typography
                            variant="caption"
                            color="error"
                            sx={{ m: '0 !important', pt: 0, pl: 2 }}
                          >
                            {
                              fields.marketingCommunicationAccepted.inputProps
                                .error
                            }
                          </Typography>
                        ) : null}
                      </Stack>
                    </Stack>
                    <Button
                      type="submit"
                      disabled={loading || domainIsLoading}
                      data-testid="submit"
                      variant="contained"
                      sx={{ width: '100%' }}
                    >
                      {loading ? (
                        <SpinnerLoader />
                      ) : (
                        translations.registration.form.buttons.submit
                      )}
                    </Button>
                  </Stack>
                  <LoadingText
                    text={translations.registration.form.fields.redirect}
                    visible={loading}
                  />
                </Stack>
              )}
            </Form>
          )}
        </Stack>
      </Stack>
      <Button
        ref={loginButtonRef}
        action={loginActionRef}
        href="/"
        variant="outlined"
        color="secondary"
        data-testid="alreadyHaveAccount"
        sx={{ mt: 4 }}
      >
        {translations.registration.footer.alreadyRegistered}
      </Button>
    </>
  );
};

export default RegistrationForm;
