import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, Stack, TextField } from '@mui/material';
import PasswordStrengthProgress from '@rossum/rossum-ui/PasswordStrengthProgress';
import { ChangeEvent, useEffect, useState } from 'react';
import { translations } from '../../../en';
import { Field } from '../../../features/form';
import {
  CheckPasswordScoreRequestBody,
  PasswordScoreCheckResponse,
} from '../../../types';

const progressByScore = [
  {
    progress: 0,
    color: 'error',
    message: 'weak',
  },
  {
    progress: 30,
    color: 'error',
    message: 'weak',
  },
  {
    progress: 60,
    color: 'warning',
    message: 'soSo',
  },
  {
    progress: 90,
    color: 'success',
    message: 'good',
  },
  {
    progress: 100,
    color: 'success',
    message: 'strong',
  },
] as const;

type PasswordValidationFieldProps = {
  field: Field<string>;
  checkPasswordStrength: (payload: CheckPasswordScoreRequestBody) => void;
  passwordScore: PasswordScoreCheckResponse;
  disabled: boolean;
  autoFocus: boolean;
  email: string;
};

const PasswordValidationField = ({
  field,
  checkPasswordStrength,
  passwordScore,
  disabled,
  autoFocus,
  email,
}: PasswordValidationFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const progressObject = progressByScore[passwordScore?.score ?? 0];

  const color = progressObject?.color ?? 'error';

  const inputHasError =
    field.inputProps.touched &&
    (color !== 'success' || field.inputProps.hasError || email === '');

  useEffect(() => {
    if ((field.inputProps.value !== '' && email !== '') || email === '') {
      checkPasswordStrength({
        password: field.inputProps.value,
        email,
      });
    }
  }, [field.inputProps.value, email]);

  return (
    <Stack
      spacing={field.inputProps.value.length > 0 ? 1 : 0}
      data-testid={inputHasError ? 'passwordInputHasError' : ''}
    >
      <TextField
        error={inputHasError}
        helperText={field.inputProps.error}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          field.handlers.setApiError('');
          field.handlers.setTouched(true);
          field.inputProps.onChange(event);
        }}
        value={field.inputProps.value}
        onBlur={field.inputProps.onBlur}
        autoFocus={autoFocus}
        disabled={disabled}
        data-testid="password"
        type={showPassword ? 'text' : 'password'}
        placeholder={translations.registration.form.fields.password}
        label={translations.registration.form.fields.password}
        autoComplete="new-password"
        size="small"
        InputProps={{
          endAdornment:
            field.inputProps.value !== '' ? (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
                data-testid="show-password-toggler"
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {showPassword ? (
                  <VisibilityOff
                    fontSize="small"
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'text.primary',
                        transition: 'smooth',
                      },
                    }}
                    data-testid="eye-off"
                  />
                ) : (
                  <Visibility
                    fontSize="small"
                    sx={{
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'text.primary',
                        transition: 'smooth',
                      },
                    }}
                    data-testid="eye-on"
                  />
                )}
              </IconButton>
            ) : null,
        }}
      />
      <PasswordStrengthProgress
        value={field.inputProps.value}
        progress={email === '' ? 0 : progressObject.progress}
        messages={passwordScore?.messages ?? []}
        scoreTranslation={
          email === ''
            ? translations.validations.requiredEmailForPassword
            : translations.passwordScore[progressObject.message]
        }
        scoreColor={color}
      />
    </Stack>
  );
};

export default PasswordValidationField;
