import {
  Alert,
  AlertTitle,
  Button,
  Grow,
  Link as MuiLink,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { translations } from '../../../en';
import { Form, useCreateField } from '../../../features/form';
import { VALIDATIONS } from '../../../types';
import Header from '../../../ui/Header';
import PageWrapper from '../../../ui/PageWrapper';
import SpinnerLoader from '../../../ui/SpinnerLoader';
import { isValidEmail, required } from '../../../utils/validations';
import { useLoginInfoHook } from '../hooks/useLoginInfoHook';

const EmailLoginForm = () => {
  const email = useCreateField<string>(
    { defaultValue: '', fieldName: 'login-email' },
    required(VALIDATIONS.LOGIN_EMAIL),
    isValidEmail,
  );

  const { submit, emailSent, loading, error } = useLoginInfoHook();

  return (
    <PageWrapper withBackground={false}>
      <Header
        title={translations.emailLogin.header.title}
        data-testid="emailLogin-title"
      />
      <Typography variant="h5" color="text.secondary" sx={{ pt: 1 }}>
        {translations.emailLogin.header.subTitle}
      </Typography>
      {emailSent ? (
        <Stack sx={{ pt: 5 }} spacing={4} alignItems="center">
          <Grow in={true}>
            <Alert
              sx={{ alignItems: 'center' }}
              variant="filled"
              severity="success"
              data-testid="email-sent"
            >
              <AlertTitle sx={{ fontWeight: 700, mb: 0 }}>
                {translations.emailLogin.emailSent.title}
              </AlertTitle>
              {translations.emailLogin.emailSent.message}
            </Alert>
          </Grow>

          <Typography variant="caption" color="text.disabled" align="center">
            {translations.emailLogin.emailSent.problem}{' '}
            <MuiLink
              href="mailto:support@rossum.ai"
              sx={{
                color: (theme) => theme.palette.text.primary,
                textDecorationColor: 'inherit',
                '&:hover': { textDecoration: 'none' },
              }}
            >
              {translations.emailLogin.emailSent.helpLink}
            </MuiLink>
          </Typography>
        </Stack>
      ) : (
        <Stack>
          <Form
            fields={{ email }}
            onSubmit={() => {
              submit(email.inputProps.value);
            }}
          >
            {({ fields }) => (
              <Stack sx={{ pt: 4, width: 330, maxWidth: '100%' }} spacing={4}>
                <Stack spacing={2}>
                  <TextField
                    placeholder={translations.emailLogin.input.placeholder}
                    label={translations.emailLogin.input.label}
                    value={fields.email.inputProps.value}
                    onChange={fields.email.inputProps.onChange}
                    error={fields.email.inputProps.hasError}
                    helperText={fields.email.inputProps.error}
                    data-testid="emailLogin-input"
                    size="small"
                  />
                  <Button
                    disabled={loading}
                    variant="contained"
                    type="submit"
                    data-testid="emailLogin-button"
                  >
                    {loading ? (
                      <SpinnerLoader />
                    ) : (
                      translations.emailLogin.submit
                    )}
                  </Button>

                  {error ? (
                    <Typography
                      data-testid="emailLogin-responseError"
                      color="error"
                      variant="body2"
                      align="center"
                    >
                      {error}
                    </Typography>
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.disabled"
                      align="center"
                    >
                      {translations.emailLogin.submitDescription}
                    </Typography>
                  )}
                </Stack>
                <Button
                  component={Link}
                  to="/registration"
                  color="secondary"
                  variant="outlined"
                  sx={{ display: 'inline', alignSelf: 'center' }}
                  data-testid="createAccount"
                >
                  {translations.emailLogin.goToRegistration}
                </Button>
              </Stack>
            )}
          </Form>
        </Stack>
      )}
    </PageWrapper>
  );
};

export default EmailLoginForm;
