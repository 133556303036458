import { useDomainCookieCheck } from './hooks/useDomainCookieCheck';
import EmailLoginForm from './partials/EmailLoginForm';
import Redirecting from './partials/Redirecting';

const Page = () => {
  const { redirectState } = useDomainCookieCheck();

  return redirectState === 'isSettled' ? <EmailLoginForm /> : <Redirecting />;
};

export default Page;
