import { CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { translations } from '../../../en';
import LoadingText from '../../../ui/LoadingText';
import PageWrapper from '../../../ui/PageWrapper';

const RENDER_PROGRESS_DELAY = 500;
const RENDER_TEXT_DELAY = 3000;

const Redirecting = () => {
  const [displayProgress, setDisplayProgress] = useState(false);
  const [displayText, setDisplayText] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(
      () => setDisplayProgress(true),
      RENDER_PROGRESS_DELAY,
    );
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => setDisplayText(true), RENDER_TEXT_DELAY);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <PageWrapper>
      <Stack
        sx={{ width: 330, maxWidth: '100%' }}
        spacing={4}
        alignItems="center"
      >
        {displayProgress && (
          <CircularProgress data-testid="redirectionLoader" />
        )}
        {displayText && (
          <LoadingText
            text={translations.emailLogin.redirecting}
            visible={true}
          />
        )}
      </Stack>
    </PageWrapper>
  );
};

export default Redirecting;
