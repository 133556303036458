import { defaultTo, get, isFunction, values as _values } from 'lodash';
import { translations } from '../../../en';
import {
  ValidationFunction,
  VALIDATIONS,
  ValidationType,
} from '../../../types';
import { getValidationKey } from '../../../utils/helpers';

const getErrorTranslation = (key: ValidationType) => {
  const validationKey = getValidationKey(key);

  const translation = get(
    translations.validations,
    validationKey,
    validationKey,
  );

  if (isFunction(translation)) {
    const values = get(key, 'values');
    return translation(values);
  }
  return translation;
};

export function getErrorMessage<T>(
  val: T,
  validations: Array<ValidationFunction<T>>,
) {
  const validationKey = defaultTo(
    validations
      .map((validation) => validation(val))
      .find((message: ValidationType) => !!getValidationKey(message)),
    VALIDATIONS.OK,
  );

  return getErrorTranslation(validationKey);
}
