import { forEach, get, isFunction } from 'lodash';
import { GlobalError, ResponseType, SetStateAction } from '../../../types';
import { reportToRollbar } from '../../../utils/report';

type ErrorSetter = (message: string) => void;

interface ErrorSetters {
  [key: string]: ErrorSetter;
}

export const handleApiErrors =
  (
    error: ResponseType,
    fieldErrorSetters: ErrorSetters,
    setGlobalError: SetStateAction<GlobalError | null>,
    payload = {},
  ) =>
  () => {
    forEach({ ...fieldErrorSetters }, (e) => e(''));
    setGlobalError(null);

    const fields = Object.keys(fieldErrorSetters);

    if (error) {
      reportToRollbar(error, payload);

      const fieldsErrors = Object.keys(error.response)?.filter((key) =>
        fields.includes(key),
      );

      if (fieldsErrors.length > 0) {
        return fieldsErrors.forEach((field) => {
          if (isFunction(fieldErrorSetters[field])) {
            return fieldErrorSetters[field](error.response[field]?.[0]);
          }

          return setGlobalError({
            message: 'Something went wrong',
            status: get(error, 'status'),
          });
        });
      }

      return setGlobalError({
        message: 'Something went wrong',
        status: get(error, 'status'),
      });
    }
  };
