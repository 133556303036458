/* tslint:disable:no-any */
export const initGTM = () => {
  (window as any).dataLayer = (window as any).dataLayer || [];

  ((w: any, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  })(window, document, 'script', 'dataLayer', 'GTM-ML6R7M5');

  const gtm = document.createElement('script');
  gtm.async = true;
  gtm.defer = true;
  gtm.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-ML6R7M5';
  document.head.appendChild(gtm);
};
