import { every, isString, mapValues } from 'lodash';
import { parse, ParsedQuery } from 'query-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PHONE_NUMBER_MAX_LENGTH, StepT } from '../../../config/values';
import { SetStateAction } from '../../../types';

const assureString = (value: ParsedQuery<string>[string]) => {
  return isString(value) ? value : '';
};

const useHashToFields = ({
  setActiveStep,
}: {
  setActiveStep: SetStateAction<StepT>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    firstName,
    lastName,
    companyName,
    phoneNumber,
    workEmail,
    communicationOptIn,
    termsAccepted,
  } =
    (location.state as Record<string, string> | undefined) ||
    parse(location.hash);

  // parse() decodes the uri by default with decode-uri-component which is good but the "+"
  // is decoded and replaced with a space " " we would like to persist decoding, but we need
  // to be able to pass an email addresses or phone number dial with "+"
  const workEmailSanitized =
    typeof workEmail === 'string' && workEmail.includes(' ')
      ? workEmail.replaceAll(' ', '+')
      : workEmail;

  const phoneNumberSanitized =
    typeof phoneNumber === 'string'
      ? phoneNumber
          .replace(RegExp(/^\s/), '+')
          .substring(0, PHONE_NUMBER_MAX_LENGTH)
      : phoneNumber;

  const values = mapValues(
    {
      firstName,
      lastName,
      companyName,
      phoneNumber: phoneNumberSanitized,
      workEmail: workEmailSanitized,
      communicationOptIn,
      termsAccepted,
    },
    assureString,
  );

  const { phoneNumber: _phoneNumber, ...requiredValues } = values;

  const hasAllUrlParams = every(requiredValues);

  useEffect(() => {
    if (location.hash) {
      navigate({ hash: '' }, { replace: true, state: values });
    }

    if (hasAllUrlParams) {
      setActiveStep('account');
    }
  }, []);

  return { valuesFromUrl: values };
};

export default useHashToFields;
