import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { AjaxResponse } from 'rxjs/ajax';
import { ErrorResponse } from '../types';

export const collections = [
  [/auth\/login/i, 'auth/login'],
  ['/organizations/create', 'organizations/create'],
  ['default', 'any'],
];

export const inferCollection = (url: string) => {
  const matched = find(collections, ([endpoint]) =>
    endpoint === 'default'
      ? true
      : endpoint instanceof RegExp
      ? url.match(endpoint)
      : includes(url, endpoint),
  ) as [string | RegExp, string];

  return matched[1];
};

export const reportToRollbar = (
  error: AjaxResponse<ErrorResponse>,
  payload = {},
) => {
  const rollbar = get(window, 'Rollbar');
  if (rollbar) {
    const status = get(error, 'status', 'Unknown status');
    const method = get(error, 'request.method', 'Unknown method');
    const url = get(error, 'request.url', 'default');

    const title = `${status} ${method} (${inferCollection(url)})`;

    rollbar
      .configure({
        payload: {
          fingerprint: title,
          title,
          ...payload,
        },
        // Optional chaining is there because some browsers block calls to external URIs
        // (Rollbar.configure is then undefined and methods can't be called on that)
      })
      ?.error({ response: error.response });
  }
};
