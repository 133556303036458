import { ajax } from 'rxjs/ajax';
import { envValues } from '../config/envValues';
import {
  CheckDomainRequestBody,
  CheckPasswordScoreRequestBody,
  LoginInfoRequestBody,
  OrganizationCreateRequestBody,
  OrganizationCreateResponse,
  PasswordScoreCheckResponse,
  RequestBodyPost,
} from '../types';

const post = <T = unknown>(url: string, body: RequestBodyPost, headers = {}) =>
  ajax.post<T>(url, body, { 'Content-Type': 'application/json', ...headers });

export const createOrganization = (body: OrganizationCreateRequestBody) =>
  post<OrganizationCreateResponse>(
    `${envValues.apiUrl}/organizations/create`,
    body,
    {
      'X-Rossum-Request-Origin': 'web_app',
    },
  );

export const checkPasswordScore = (body: CheckPasswordScoreRequestBody) =>
  post<PasswordScoreCheckResponse>(
    `${envValues.apiUrl}/auth/password/score`,
    body,
  );

// Manage endpoints do not have /v1 in the URL.
const manageEndpointsApiUrl = `${envValues.apiUrl.replace('/v1', '')}/manage`;

export const checkDomain = (body: CheckDomainRequestBody) =>
  post(`${manageEndpointsApiUrl}/check_domain`, body);

export const loginInfo = (body: LoginInfoRequestBody) =>
  post(`${manageEndpointsApiUrl}/global_login_info`, body);
