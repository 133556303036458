import Cookies from 'js-cookie';
import { ROSSUM_APP_DOMAIN } from './constants';
import { getCookieValidityDomain } from './helpers/getCookieValidityDomain';

const cookieDomainValidity = getCookieValidityDomain(
  new URL(window.location.origin),
);

export const setRossumAppDomainCookie = (domain: string) => {
  if (cookieDomainValidity) {
    Cookies.set(ROSSUM_APP_DOMAIN, domain, {
      domain: cookieDomainValidity,
      expires: 365,
      path: '/',
    });
  }
};

export const removeRossumAppDomainCookie = () => {
  return Cookies.remove(ROSSUM_APP_DOMAIN, { domain: cookieDomainValidity });
};

export const getRossumAppDomainCookie = () => {
  return Cookies.get(ROSSUM_APP_DOMAIN);
};
