import { useCallback, useEffect, useState } from 'react';
import { getRossumAppDomainCookie } from '../../../features/appDomain';

type RedirectState = 'isResolving' | 'isRedirecting' | 'isSettled';

export const useDomainCookieCheck = () => {
  const [redirectState, setRedirectState] =
    useState<RedirectState>('isResolving');

  const setIsSettled = useCallback(() => setRedirectState('isSettled'), []);

  useEffect(() => {
    if (redirectState === 'isResolving') {
      const domainInCookie = getRossumAppDomainCookie();

      if (domainInCookie) {
        setRedirectState('isRedirecting');
        window.location.replace(`https://${domainInCookie}`);

        return;
      }

      setIsSettled();
    }
  }, [redirectState]);

  return {
    redirectState,
    setIsSettled,
  };
};
