import { AjaxResponse } from 'rxjs/ajax';

export type SetStateAction<T> = React.Dispatch<React.SetStateAction<T>>;

export type ChangeHandler<T> = (e: React.ChangeEvent<T>) => void;

export type InputChangeHandler = ChangeHandler<HTMLInputElement>;

export type ValidationType = VALIDATIONS | { key: VALIDATIONS; values: {} };

export type ValidationFunction<T> = (value: T) => ValidationType;

export type ValidationFunctionBoolean = (value: boolean) => ValidationType;

export type ErrorFieldResponse = { [key: string]: Array<string> };

export type ErrorResponse = Partial<{
  code: string;
  detail: string;
  non_field_errors: Array<string>;
}> &
  ErrorFieldResponse;

export type ResponseType<T = ErrorResponse> = void | AjaxResponse<T> | null;

export type SubmitFunction = (body: FormValues) => void;

export type CheckFunction = (domain: string) => void;

export type LoginInfoFunction = (email: string) => void;

export type PasswordScoreCheckFunction = (
  payload: CheckPasswordScoreRequestBody,
) => void;

export type PasswordScoreCheckResponse = {
  score: 0 | 1 | 2 | 3 | 4;
  messages: Array<string>;
} | null;

export interface GlobalError {
  status: number;
  message: string;
}

export interface UISettings {
  features: {
    usage_reports: boolean;
  };
}

interface UserUISettings {
  hints: {};
  locale: string;
  isUsingNewDashboard: boolean;
}

export type OrganizationCreateRequestBody = {
  create_key: string;
  organization_name: string;
  template_name: string;
  user_email: string;
  user_fullname: string;
  user_password: string;
  user_ui_settings: UserUISettings;
  phone_number?: string;
  communication_opt_in: boolean;
  domain?: string; // TODO: make it required after remove custom domain from below DPC_ENABLED
};

export interface CheckDomainRequestBody {
  domain: string;
}
export interface CheckPasswordScoreRequestBody {
  password: string;
  email?: string;
}

export type LoginInfoRequestBody = {
  email: string;
};

export interface OrganizationResponse {
  id: number;
  ui_settings: UISettings;
}

export type OrganizationCreateResponse = {
  organization: OrganizationResponse;
  key: string;
  domain: string;
  code: string;
};

export type FormValues = {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  marketingCommunicationAccepted: boolean;
  password: string;
  phoneNumber: string;
  termsAccepted: boolean;
  domain?: string; // TODO: make it required after remove custom domain from below DPC_ENABLED
};

export enum VALIDATIONS {
  NOT_VALID_PHONE = 'notValidPhone',
  REQUIRED = 'required',
  REQUIRED_FIRST_NAME = 'requiredFirstName',
  REQUIRED_LAST_NAME = 'requiredLastName',
  REQUIRED_COMPANY_NAME = 'requiredCompanyName',
  REQUIRED_DOMAIN = 'requiredDomain',
  REQUIRED_EMAIL = 'requiredEmail',
  LOGIN_EMAIL = 'loginEmail',
  REQUIRED_PASSWORD = 'requiredPassword',
  TERMS_REQUIRED = 'termsRequired',
  TOO_SHORT = 'tooShort',
  TOO_SHORT_DIGITS = 'tooShortDigits',
  TOO_LONG_DIGITS = 'tooLongDigits',
  NOT_VALID_EMAIL = 'notValidEmail',
  NOT_VALID_DOMAIN = 'notValidDomain',
  REQUIRED_EMAIL_FOR_PASSWORD = 'requiredEmailForPassword',
  OK = '',
}

export type RequestBodyPost =
  | OrganizationCreateRequestBody
  | CheckDomainRequestBody
  | CheckPasswordScoreRequestBody
  | LoginInfoRequestBody;

declare global {
  interface Window {
    dataLayer: Array<unknown> | undefined;
  }
}
